import React from 'react'
import { Link } from 'react-router-dom'

export default function ServicePricing() {
    return (
        <>
            {/* <!-- pricing section start --> */}
            <section className="pricing-area pricing-area__2 pt-70 pb-70">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 text-center">
                            <div className="section-header mb-80">
                                <h4 className="sub-heading sub-heading__2 mb-25">
                                    <span><img src="/images/shape/heading-shape-3.png" className="mr-10" alt="" width="14" height="14"  /></span>
                                    affordable price
                                    <span><img src="/images/shape/heading-shape-4.png" className="ml-10" alt="" width="14" height="14"  /></span>
                                </h4>
                                <h2 className="section-title section-title__2">Pricing Package</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-none-30 justify-content-center">
                        <div className="col-xl-4 col-lg-6 mt-30">
                            <div className="pricing-item">
                                <div className="pricing-item__head">
                                    <h3 className="heading-title">Custom Patches</h3>
                                </div>
                                <ul className="pricing-item__lists">
                                <li className='px-3'>
                                        <select aria-label="Select Patch Type" style={{width:"100%"}}>
                                            <option>Select Type</option>
                                            <option>Embroidered</option>
                                            <option>Chenille</option>
                                            <option>Woven</option>
                                            <option>PVC</option>
                                            <option>Printed</option>
                                            <option>Leather</option>
                                            <option>Combined</option>
                                        </select>
                                    </li>
                                <li className='px-3'>
                                        <select aria-label="Select One Fabric" style={{width:"100%"}}>
                                            <option>Select Fabric</option>
                                            <option>Polyester</option>
                                            <option>Nylon</option>
                                            <option>Felt</option>
                                        </select>
                                    </li>
                                    <li className='px-3'>
                                        <select aria-label="Select One Thread" style={{width:"100%"}}>
                                            <option>Select Thread</option>
                                            <option>Simple thread</option>
                                            <option>Multi thread</option>
                                            <option>metallic thread</option>
                                        </select>
                                    </li>
                                    <li className='px-3'>
                                        <select aria-label="Select One Backing"  style={{width:"100%"}}>
                                        <option>Select Backing</option>
                                        <option>Iron On</option>
                                        <option>Velcro</option>
                                        <option>Sew-On</option>
                                        <option>Adhesive</option>
                                        <option>Plastic</option>
                                        <option>No Backing</option>
                                        </select>
                                        </li>
                                   <li className='px-3'>
                                    <select aria-label="Select One Border"  style={{width:"100%"}}>
                                   <option>Select Border</option>
                                    <option>Hot Cut Border</option>
                                    <option>Merrowed Border</option>
                                    <option>Satin Stitch Border</option>
                                    </select>
                                    </li>
                                    <li>Additional Colors: 15¢</li>
                                    <li>Free Shipping</li>
                                    <li>Free Samples</li>
                                </ul>
                                <Link to="/contact#contact" className="price-btn">
                                    <span className="icon"><i className="far fa-arrow-right"></i></span>
                                    Request Quote
                               </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- pricing section end -->    */}
        </>
    )
}
