import React from 'react';
import { NavLink } from 'react-router-dom';

const ContactBanner = () => {
  const styles = {
    banner: {
      width: '100%',
      height: '300px',
      backgroundColor: '#3e3e7a', // Updated background color
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '10px',
      margin: '0 auto',
      boxSizing: 'border-box',
      color: '#fff ' , // White text color for contrast
      textAlign: 'center',
    //   border: '5px solid #000', 
    },
    icon: {
      fontSize: '30px',
      marginBottom: '10px',
    },
    heading: {
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '10px',
      color: '#fff ' , 
    },
    paragraph: {
      fontSize: '16px',
      lineHeight: '1.5',
      maxWidth: '800px',
      margin: '10px auto 20px',
      color: '#fff ' , 
    },
    buttonContainer: {
      display: 'flex',
      gap: '15px',
      marginTop: '15px',
    },
    button: {
      padding: '12px 25px',
      fontSize: '14px',
      fontWeight: '600',
      color: '#fff',
      backgroundColor: '#000',
      border: 'none',
      borderRadius: '50px', // Capsule shape
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
      letterSpacing: '3px',
    },

    

    buttonHover: {
      backgroundColor: '#0C2957', // Slightly lighter background on hover
    },
  };

  return (
    <div className='mt-100' style={styles.banner}>
      <div style={styles.icon}></div>
      <h3 style={styles.heading}>Contact Us</h3>
      <p style={styles.paragraph}>
      Do you need help with our services or with your order?
      Our team is here to help! Let us know your query, and we'll reply promptly.
      </p>
      <div style={styles.buttonContainer}>
        <NavLink  to="tel:+1 929-279-8016" aria-label="Call Option" >
          <button
          style={styles.button}
          onMouseEnter={(e) => (e.target.style.backgroundColor = styles.buttonHover.backgroundColor)}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#000')}
        > 
        
        CALL US</button>
        </NavLink>
      <NavLink  to="mailto:info@silkdigitizing.com" aria-label='Email Option'>  
        <button
          style={styles.button}
          onMouseEnter={(e) => (e.target.style.backgroundColor = styles.buttonHover.backgroundColor)}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#000')}
        >
           EMAIL US
        </button>
        </NavLink> 
      </div>
    </div>
  );
};

export default ContactBanner;
