import axios from 'axios';
import React, { useState } from 'react';
export default function FAQGTA() {
     // Form Code Starts Here
     const [userdata, setUserdata] = useState({
        name: "",
        email: "",
        phone: "",
        service: "",
        message: "",
        file: null
    });

    const [statusMessage, setStatusMessage] = useState({ text: "", type: "" });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserdata({
            ...userdata,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        setUserdata({
            ...userdata,
            file: e.target.files[0]
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData();
            formData.append("name", userdata.name);
            formData.append("email", userdata.email);
            formData.append("phone", userdata.phone);
            formData.append("service", userdata.service);
            formData.append("message", userdata.message);
            if (userdata.file) {
                formData.append("file", userdata.file);
            }

         const res =   await axios.post('https://www.silkdigitizing.com/cp/contact_action.asp', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
            console.log('API Response:', res.data); // Log the response
            setStatusMessage({ text: "Message Sent", type: "success" });

            // Clear the status message 
            setTimeout(() => {
                setStatusMessage({ text: "", type: "" });
            }, 5000);
        } catch (err) {
            setStatusMessage({ text: "Try Again, Sending Failed", type: "error" });

            // Clear the status message 
            setTimeout(() => {
                setStatusMessage({ text: "", type: "" });
            }, 5000);
        }
    };
    //Form Code Ends
    return (
        <>
        {statusMessage.text && (
                <div className={`status-message ${statusMessage.type} fade`}>
                    {statusMessage.text}
                </div>
            )}
            {/* <!-- gta section start --> */}
            <section className="gta-area grey-bg pt-125 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div id="contact-map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3349.504252964468!2d-96.8858286!3d32.9112732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c279886300001%3A0x922f8b2ab56a96df!2sSILK%20DIGITIZING!5e0!3m2!1sen!2s!4v1709723459995!5m2!1sen!2s" width="600" height="700" style={{border:"0",width:'100%'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                        <div className="col-xl-6 pl-70">
                            <div className="section-header mb-50">
                                <h4 className="sub-heading sub-heading__2 mb-15">Send Message
                                    <span><img src="/images/shape/heading-shape-4.png" className="ml-10" alt="" width="14" height="14"  /></span>
                                </h4>
                                <h2 className="section-title section-title__2">Get in Touch</h2>
                            </div>
                            
                            <div className="contact-form">
                            <form onSubmit={handleSubmit} id="contact-form">
                                    <div className="form-group mt-25">
                                        <input type="text" name="name" id="name" placeholder="Contact Name" value={userdata?.name}
                                            onChange={handleChange} />
                                    </div>
                                    <div className="form-group mt-25">
                                        <input type="email" name="email" id="email" placeholder="Contact Email" value={userdata?.email}
                                            onChange={handleChange} />
                                    </div>
                                    <div className="form-group mt-25">
                                        <input type="tel" name="phone"
                                            id="tel"
                                            placeholder="Contact Number"
                                            value={userdata?.phone}
                                            onChange={handleChange} />
                                    </div>
                                    <div className="form-group mt-25">
                                        <select
                                            name="service"
                                            id="service"
                                            value={userdata?.service}
                                            onChange={handleChange}
                                            style={{ width: '100%' }}
                                        >
                                            <option value="" disabled>Choose Service</option>
                                            <option value="Embroidery Digitizing">Embroidery Digitizing</option>
                                            <option value="Vector Conversion">Vector Conversion</option>
                                            <option value="Custom Patches">Custom Patches</option>
                                        </select>
                                    </div>
                                    
                                    <div className="form-group mt-25">
                                        <input
                                            type="file"
                                            name="file"
                                            id="file"
                                            onChange={handleFileChange}
                                        />
                                    </div>

                                    <div className="form-group mt-25">
                                        <textarea name="message" id="message" placeholder="Your Message" value={userdata?.message}
                                            onChange={handleChange}></textarea>
                                    </div>
                                    
                                    <button type="submit" className="site-btn site-btn__2 mt-15">
                                        <span className="icon icon__black"><i className="far fa-arrow-right"></i></span> Contact us
                                    </button>
                                    <p className="ajax-response"></p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- gta section end -->    */}
        </>
    )
}
