import React from 'react'
import Layout1 from '../layouts/Layout1'
import { Helmet } from 'react-helmet'
import EmbroideryDigitizing from '../components/ServiceDetails/EmbroideryDigitizing'

export default function EmbroideryDigitizingPage() {

    return (
        <>

            <Layout1>
                <Helmet>

                    <title>Embroidery Digitizing Services | Silk Digitizing</title>
                    <meta name="description" content="Get precise and high-quality embroidery digitizing services from Silk Digitizing. We transform your designs into stunning embroidery masterpieces." />
                    <meta name="keywords" content="embroidery digitizing, digitizing services, embroidery design, Silk Digitizing, custom embroidery, digital embroidery, embroidery art" />
                    <link rel="canonical" href="https://silkdigitizing.com/services/embroidery-digitizing"></link>
                </Helmet>
                <EmbroideryDigitizing />
            </Layout1>
        </>
    )
}
