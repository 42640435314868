import React from 'react';

const VectorConversionWorkingProcess = () => {
    
  return (
    <div><div className='col-xl-12'>
                <div className="details-content">
                  
                         
                     {/* Working Process */}
                     <div className="content mt-15 mb-0 text-center">
                        <h3 className="details-content__title mt-30 mb-25">Working Process</h3>
                    </div>
                    <div className="row mt-none-30 mr-0 " style={{ '--bs-gutter-x': '0rem' }}>
                        <div className="col-xl-4 col-lg-6 col-md-6 mt-30">
                            <div className="wp-box wp-box__2 text-center">
                                <div className="wp-box__icon wp-box__icon--center wp-box__icon--2 mb-25">
                                    <img src="/images/icons/w-p-1.png" alt="" width="50" height="50" />
                                </div>
                                <div className="wp-box__content">
                                    <h4 className="wp-box__title wp-box__title--2">Booking Online</h4>
                                    <p>Effortlessly book vector conversion services online for convenient customization and efficiency.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 mt-30">
                            <div className="wp-box wp-box__2 text-center">
                                <div className="wp-box__icon wp-box__icon--center wp-box__icon--3 mb-25">
                                    <img src="/images/icons/w-p-3.png" alt="" width="64" height="46" />
                                </div>
                                <div className="wp-box__content">
                                    <h4 className="wp-box__title wp-box__title--2">Creative Design</h4>
                                    <p>Transforming raster images into scalable vectors, ensuring precision and quality throughout.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 mt-30">
                            <div className="wp-box wp-box__2 text-center">
                                <div className="wp-box__icon wp-box__icon--center wp-box__icon--4 mb-25">
                                    <img src="/images/icons/w-p-4.png" alt="" width="55" height="44" />
                                </div>
                                <div className="wp-box__content">
                                    <h4 className="wp-box__title wp-box__title--2">Process Orders</h4>
                                    <p>Efficiently receive and process orders for seamless vector conversion services online.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
          
    </div>
    </div>
  )
}

export default VectorConversionWorkingProcess;