import React from 'react'

export default function HomeTwoAbout() {
    return (
        <>
            {/* <!-- about section start --> */}
            <section className="about-area pt-100 pb-100">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-xl-5">
                            <div className="about__bg about__bg--2 mt-75">
                                <div className="big-thumb">
                                    <img src="/images/HomePage/left-chest-patch-black-outfit.webp" alt="" width={526} height={567} style={{ maxWidth: '100%' }} />
                                </div>
                                <div className="mid-thumb position-absulate">
                                    <img src="/images/HomePage/multi-color-patch1.webp" alt="" width={271} height={222} style={{ maxWidth: '100%' }} />
                                </div>
                                <div className="small-thumb position-absulate">
                                    <img src="/images/HomePage/multi-color-patch2.webp" alt="" width={230} height={144} style={{ maxWidth: '100%' }} />
                                </div>
                                <span className="circle-shape position-absulate"><img src="/images/shape/border-shape-2.avif" alt="" width={224} height={224} style={{ maxWidth: '100%' }} /></span>
                                <span className="patternt-shape position-absulate"><img src="/images/shape/about-shape-1.avif" alt="" width={210} height={226} style={{ maxWidth: '100%' }} /></span>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="section-header section-header__2 mb-40">
                                <h2 className="sub-heading sub-heading__2 mb-10">About Us <span><img
                                    src="/images/shape/heading-shape-4.avif" className="ml-10" alt="" width={14} height={14} style={{ maxWidth: '100%' }} /></span></h2>
                                <h2 className="section-title section-title__2 mb-30">FROM CONCEPT TO CREATION</h2>
                                <p>
                                    Welcome to Silk Digitizing, your premier destination for
                                    embroidery digitizing and vector conversion. 
                                    Stick with us for turning designs into marvelous embroidery pieces and scalable vectors!
                                </p>
                            </div>
                            <div className="row mt-none-40">
                                <div className="col-xl-6 mt-40">
                                    <div className="ab__box">
                                        <div className="ab__box--head">
                                            <div className="icon">
                                                <img src="/images/icons/ab-1.avif" alt="" width={40} height={39} style={{ maxWidth: '100%' }} />
                                            </div>
                                            <h3 className="title">Swift, <br /> Quality Service</h3>
                                        </div>
                                        <p>For top-notch quality and lightning-fast service, choose Silk Digitizing. Elevate your projects with us today!</p>
                                    </div>
                                </div>
                                <div className="col-xl-6 mt-40">
                                    <div className="ab__box">
                                        <div className="ab__box--head">
                                            <div className="icon icon__2">
                                                <img src="/images/icons/ab-2.avif" alt="" width={35} height={35} style={{ maxWidth: '100%' }} />
                                            </div>
                                            <h3 className="title">Elevate <br /> Your Projects</h3>
                                        </div>
                                        <p>With a commitment to excellence, we elevate your projects to new heights of quality and precision.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- about section end -->    */}
        </>
    )
}
