import React from 'react';
import { Link } from 'react-router-dom';



const styles = {
  prominentButton: {
    padding: '7px 12px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#ffffff',
    backgroundColor: '#3e3e7a',
    backgroundImage: 'linear-gradient(135deg, #3e3e7a 0%, #5a5a99 100%)', // Gradient in shades of #3e3e7a
    border: 'none',
    // borderRadius: '12px',
    borderRadius: '3px',
    cursor: 'pointer',
    transition: 'transform 0.2s, background-color 0.2s',
    display: 'inline-block',
  },
  prominentButtonHover: {
    backgroundImage: 'linear-gradient(135deg, #5a5a99 0%, #3e3e7a 100%)', // Reversed gradient for hover effect
  },
};

// Add a hover effect by attaching an event to apply hover style
const handleMouseEnter = (e) => {
  e.target.style.backgroundImage = styles.prominentButtonHover.backgroundImage;
  e.target.style.transform = 'scale(1.1)';
};

const handleMouseLeave = (e) => {
  e.target.style.backgroundImage = styles.prominentButton.backgroundImage;
  e.target.style.transform = 'scale(1)';
};

// Attach the hover effect to the button
const Phone = () => (
    <Link to="tel:+1 929-279-8016" aria-label="Call Option"> 
    <button
    style={styles.prominentButton}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
  >
    <i className="fal fa-phone" ></i> +1 929-279-8016
    
  </button></Link>
);

export default Phone;
