import React from 'react'

export default function HomeTwoWCU() {
    return (
        <>
            {/* <!-- wcu area start --> */}
            <section className="wcu-area pt-100 pb-100 blue-bg mt-15">
                <div className="container">
                    <div className="row justify-content-end">
                        <div className="col-xl-6">
                            <div className="section-header section-header__white section-header__wcu mb-50">
                                <h3 className="sub-heading sub-heading__2 sub-heading__red mb-15 text-white">
                                    why choose us
                                    <span><img src="/images/shape/heading-shape-4.avif" className="ml-5" width={14} height={14} style={{maxWidth: '100%'}} alt=""/></span>
                                </h3>
                                <h2 className="section-title section-title__2 section-title__white mb-30"> CUSTOM EMBROIDERY DIGITIZING</h2>
                                <p>We offer custom embroidery digitizing services,
transforming your artwork into precise digital files for
flawless stitching. Enhance your products with unique,
high-quality embroidery, perfect for branding and
personalization needs.</p>
                            </div>
                            <div className="wcu-box__wrapper">
                                <div className="wcu-box">
                                    <div className="wcu-box__icon">
                                        <img src="/images/icons/thumbs-up-heart.webp" alt="" width={42} height={38} style={{maxWidth: '100%'}}/>
                                    </div>
                                    <div className="wcu-box__content">
                                        <h3 className="wcu-box__title">Satisfied Service</h3>
                                        <p>Experience satisfaction beyond expectation with our dedicated service. We prioritize your needs, ensuring that every interaction with us leaves you delighted and fulfilled.</p>
                                    </div>
                                </div>
                                <div className="wcu-box mt-40">
                                    <div className="wcu-box__icon">
                                        <img src="/images/icons/24-hours-support-call.webp" alt="" width={39} height={39} style={{maxWidth: '100%'}}/>
                                    </div>
                                    <div className="wcu-box__content">
                                        <h3 className="wcu-box__title">Free Consultancy</h3>
                                        <p>Gain valuable insights and guidance with our complimentary consultancy services. Our experts are here to assist you every step of the way, providing tailored recommendations to meet your specific requirements.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wcu-shape">
                    <img loading="lazy" src="/images/HomePage/WE-LOVE-OUR-CUSTOMERS-.avif" alt="Our Message" width={650} height={670} style={{maxWidth: '100%', borderRadius:"25px"}}/>
                </div>
            </section>
            {/* <!-- wcu area end -->    */}
        </>
    )
}
