import React from 'react';
import { useEffect, useState } from 'react';

const VectorConversionLast = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 500px)');
    const handleMediaChange = (e) => setIsSmallScreen(e.matches);

    // Check initial screen size
    setIsSmallScreen(mediaQuery.matches);

    // Add listener for media query changes
    mediaQuery.addEventListener('change', handleMediaChange);

    // Cleanup event listener on component unmount
    return () => mediaQuery.removeEventListener('change', handleMediaChange);
  }, []);

  const styles = {
    container: {
      maxWidth: '1200px', // Limit the max width on larger screens
      margin: '0 auto',
      padding: '20px',
    },
    title: {
      fontSize: '24px',
      marginBottom: '10px',
      textAlign: 'left',
      margin: '0 18px',
      fontWeight: '600',
      color: '#0C2957',

    },
    paragraph: {
      width: '100%', // Full width on mobile
      maxWidth: '1200px', // Limit width for readability on larger screens
      margin: '15px 20px',
      //   marginBottom: '20px',
      fontSize: '16px',
      lineHeight: '1.6',
      color: '#333',
      textAlign: 'left',
    },
    contentRow: {
      display: 'flex',
      flexWrap: 'wrap', // Allows wrapping on smaller screens
      gap: '12px', // Space between list and image
      justifyContent: 'space-between',

    },
    listContainer: {
      flex: '1 1 60%', // Occupies around 60% of width on larger screens
      maxWidth: '500px',
      marginLeft: '18px'
    },
    list: {
      listStyleType: 'disc',
      paddingLeft: '20px', // Aligns bullets
      fontSize: '16px',
      lineHeight: '1.5',
      color: '#333',
    },
    listItem: {
      marginBottom: '10px',
    },
    image: {
      flex: '1 1 40%', // Occupies around 40% of width on larger screens
      maxWidth: isSmallScreen ? '330px' : '630px', // Responsive max width
      width: '100%',
      height: 'auto',
      marginBottom: '20px', // Spacing below on mobile
      order: 2, // Ensures image is on the right side
    },
  };

  return (
    <div style={styles.container}>
      {/* Title Section */}
      <h2 style={styles.title}>Vector Conversion Services</h2>

      {/* Paragraph Section */}
      <p style={styles.paragraph}>
        We specialize in converting images into crisp, scalable vector files with rapid turnaround. Known as vector conversion, this process goes beyond simple format changes. Instead, it transforms any graphic design into precise, high-quality vector artwork ideal for resizing without losing clarity. This service requires advanced software, technical expertise, and attention to detail,
        ensuring professional results for logos, graphics, and other artwork across various applications.
        Both vector conversion and raster images serve distinct needs, each enhancing brand visuals in unique
        ways.</p>

      {/* Content Row with List and Image */}
      <div style={styles.contentRow}>
        {/* List Section */}
        <div style={styles.listContainer}>
          <ul style={styles.list}>
            <li style={styles.listItem}>Raster to Vector Conversion </li>
            <li style={styles.listItem}>Vector Line Art Creation</li>
            <li style={styles.listItem}>Logo Vectorization</li>
            <li style={styles.listItem}>Photo to Vector Illustration</li>
            <li style={styles.listItem}>Product/Blueprint Vectorization</li>
            <li style={styles.listItem}>Artwork Recreation</li>
            <li style={styles.listItem}>Vector Cleanup and Simplification</li>
            <li style={styles.listItem}>Color Separation for Screen Printing</li>
            <li style={styles.listItem}>Vector Tracing</li>
            <li style={styles.listItem}>Embroidery Digitizing Vector Prep</li>

          </ul>
        </div>

        {/* Image Section */}
        <div className='micEDL'>
          <img
            src="../../images/Portfolio/VectorConversion/Bear-Raster-to-Vector.webp"
            alt="Bear Design Comparison"
            style={styles.image}
            width="630" height="277"
          />
        </div>
      </div>
    </div>
  );
};



export default VectorConversionLast;
