const patchesDetailData=[{
    id:1,
    name:"Custom Patches", 
    slug:"custom-patches",
    image:"../images/Services/Embroidered-P/embroidered-cover3.webp",
    content:"With endless options for colors, shapes, and sizes, our custom patches can be tailored to fit your specific needs. High-quality embroidery ensures durability and a professional look, making them ideal for businesses, clubs, and organizations seeking a distinctive identity. Custom patches can also be used to commemorate events, achievements, or milestones, providing a lasting and meaningful keepsake. They are easy to apply, using methods like sew-on, iron-on, or velcro backing, allowing for flexibility in use. Whether you’re promoting your business, honoring accomplishments, or simply adding a personal flair to your clothing, custom patches are a stylish and cost-effective way to make a statement. Bring your ideas to life with personalized designs that leave a lasting impression.",
    booking:"Easily book your custom patches online by selecting your design, size, and style, and receive a quick confirmation for your order.",
    design:"We provide a digital mockup for approval, allowing you to review the design and suggest any changes until you're fully satisfied.",
    order:"After your approval, production begins, using top-quality materials and precision embroidery techniques to bring your custom patches to life.",
    portfolioImage:["../images/Services/Embroidered-P/embroidered-cover2.webp","../images/Services/Embroidered-P/embroidered-cover3.webp","../images/Services/Chenille-P/chenille-chenille1.webp","../images/Services/Chenille-P/chenille-cover4.webp"],
    metaTitle:"Silk Patches | Custom Patches | High-Quality Embroidery Designs",
    metaDes:"Explore our unique custom patches for all your embroidery needs. High-quality designs, fast shipping, and excellent customer service.",
    metaKeywords:"embroidery digitizing, digitizing services, embroidery design, Silk Digitizing, custom embroidery, digital embroidery, embroidery art"
},
{
    id:2,
    name:"Embroidered Patches", 
    slug:"embroidered-patches",
    image:"../images/Services/Embroidered-P/embroidered-cover1.webp",
    content:"Embroidered patches offer a classic and fashionable way to personalize clothing, accessories, and uniforms. Known for their vibrant colors and intricate detailing, these patches are created by stitching designs directly onto fabric, resulting in a high-quality, durable product. Whether you’re looking to represent a brand, commemorate a special event, or showcase team spirit, embroidered patches offer an affordable yet professional solution. They are customizable in various shapes, sizes, and thread colors, ensuring that every patch is unique. Perfect for businesses, clubs, or individuals, these patches can be sewn or ironed onto garments for a long-lasting effect. With a combination of classic craftsmanship and modern techniques, embroidered patches provide a polished and enduring way to make a statement. Whether for fashion, branding, or recognition, they are a versatile option that adds character to any item.",
    booking:"Place your embroidered patch order online with easy customization options, ensuring a fast and convenient process.",
    design:"A digital preview is sent for your approval, allowing adjustments to guarantee the embroidered patch meets your expectations.",
    order:"Once the design is confirmed, we begin production, ensuring your embroidered patches are crafted with attention to detail and quality materials.",
    portfolioImage:["../images/Services/Embroidered-P/embroidered-cover1.webp","../images/Services/Embroidered-P/embroidered-cover0.webp","../images/Services/Embroidered-P/embroidered-cover2.webp","../images/Services/Embroidered-P/embroidered-cover3.webp","../images/Services/Embroidered-P/embroidered-cover4.webp"],
    metaTitle:"Embroidery Digitizing Services | Silk Digitizing",
    metaDes:"Get precise and high-quality embroidery digitizing services from Silk Digitizing. We transform your designs into stunning embroidery masterpieces.",
    metaKeywords:"embroidery digitizing, digitizing services, embroidery design, Silk Digitizing, custom embroidery, digital embroidery, embroidery art"
},
{
    id:3,
    name:"Chenille Patches", 
    slug:"chenille-patches",
    image:"../images/Services/Chenille-P/chenille-chenille1.webp",
    content:"Chenille patches are known for their soft, textured feel and bold, eye-catching appearance, making them a popular choice for varsity jackets, team uniforms, and fashion items. These patches use a combination of looped yarn and embroidery to create a fluffy, raised surface that gives designs a unique, tactile quality. Ideal for showcasing large letters, numbers, or logos, chenille patches are often associated with school spirit, sports teams, and retro fashion trends. Customizable in various shapes, sizes, and colors, chenille patches offer a distinct and durable way to make a statement. Whether you're commemorating achievements, creating a standout look, or promoting your brand, these patches provide a creative solution. With their classic, vintage appeal and modern customization options, chenille patches are perfect for adding a stylish and textured touch to any garment or accessory.",
    booking:"Customize your chenille patch online by selecting your preferred design, colors, and size for a quick and hassle-free order experience.",
    design:"A digital design proof is provided for review and approval, allowing adjustments to ensure your chenille patch is just right.",
    order:"Once the design is approved, production begins, using high-quality materials to create your chenille patches with precision and care.",
    portfolioImage:["../images/Services/Chenille-P/chenille-chenille1.webp","../images/Services/Chenille-P/chenille-cover0.webp","../images/Services/Chenille-P/chenille-cover2.webp","../images/Services/Chenille-P/chenille-cover3.webp","../images/Services/Chenille-P/chenille-cover4.webp"],
    metaTitle:"",
    metaDes:"",
    metaKeywords:""
},
{
    id:4,
    name:"Woven Patches", 
    slug:"woven-patches",
    image:"../images/Services/Woven-P/woven-cover.webp",
    content:"Woven patches provide a sophisticated and precise solution for intricate designs that demand exceptional detail. Woven patches use finer threads compared to embroidered ones, enabling detailed patterns and crisp lines. This makes them ideal for logos, text, and designs with small elements or gradients. Woven patches maintain a smooth, flat surface, which makes them versatile for attaching to various items such as uniforms, hats, jackets, and bags. With a polished, professional look, these patches are often chosen by businesses, teams, and fashion brands seeking a clean, sophisticated finish. Woven patches are stylish, durable, and customizable in various sizes, shapes, and colors. Their lightweight design ensures comfort without compromising on quality, making them perfect for long-lasting use.",
    booking:"Easily order your woven patches online by selecting design preferences, size, and quantity, ensuring a smooth and efficient process.",
    design:"A digital sample is shared for your approval, allowing adjustments until the woven patch meets your vision.",
    order:"After approval, the production process begins, ensuring your woven patches are crafted with care and precision for a flawless finish",
    portfolioImage:["../images/Services/Woven-P/woven-cover.webp","../images/Services/Woven-P/woven-cover2.webp","../images/Services/Woven-P/woven-cover3.webp","../images/Services/Woven-P/woven-cover4.webp"],
    metaTitle:"",
    metaDes:"",
    metaKeywords:""
},
{
    id:5,
    name:"PVC Patches", 
    slug:"pvc-patches",
    image:"../images/Services/PVC-P/pvc-cover2.webp",
    content:"PVC patches are a durable and modern solution for those seeking long-lasting, weather-resistant designs. Made from soft, flexible rubber, these patches offer a sleek, three-dimensional appearance that stands out on clothing, bags, uniforms, and outdoor gear. PVC patches are perfect for intricate logos and bold designs, as they can capture fine details and bright colors with ease. Waterproof and resistant to fading, they are ideal for harsh conditions, making them a popular choice for military, tactical, and outdoor enthusiasts. Customizable in various shapes, sizes, and colors, PVC patches provide a professional and contemporary look for businesses, clubs, and organizations. Whether attached using Velcro, sewing, or adhesive, PVC patches maintain their shape and vibrancy over time, offering a practical yet eye-catching option for any need.",
    booking:"Customize your PVC patch order online, selecting design details, size, and colors, for a quick and straightforward ordering experience.",
    design:" A digital sample is provided for review, allowing revisions to ensure the design perfectly matches your expectations.",
    order:"Once the design is approved, we begin crafting your PVC patches, using high-quality materials for a durable, long-lasting finish.",
    portfolioImage:["../images/Services/PVC-P/pvc-cover4.webp","../images/Services/PVC-P/pvc-cover3.webp","../images/Services/PVC-P/pvc-cover5.webp","../images/Services/PVC-P/pvc-cover2.webp"],
    metaTitle:"",
    metaDes:"",
    metaKeywords:""
},
{
    id:6,
    name:"Printed Patches", 
    slug:"printed-patches",
    image:"../images/Services/Printed-P/printed-cover2.webp",
    content:"Printed patches are an excellent choice for complex, full-color designs that require high detail and vibrant imagery. Unlike embroidered or woven patches, printed patches allow for photo-quality precision, making them perfect for logos, artwork, or designs with gradients and intricate patterns. Using advanced printing techniques, these patches can capture even the finest details, offering a sleek, smooth finish. Printed patches are lightweight, durable, and ideal for both casual and professional use. Whether used on uniforms, promotional items, or fashion accessories, they offer a versatile and cost-effective solution for any custom project. With the ability to replicate multi-colored designs without compromising on quality, printed patches are popular among businesses, sports teams, and creative individuals looking for a bold and clear representation of their brand or vision.",
    booking:"Quickly place your printed patch order online by customizing your design, size, and quantity for a seamless ordering process.",
    design:"A digital proof is provided for your approval, allowing adjustments until you’re fully satisfied with the printed patch design.",
    order:"Once approved, we begin printing your patches with precision, ensuring vibrant colors and sharp details for a high-quality finish.",
    portfolioImage:["../images/Services/Printed-P/printed-cover1.webp","../images/Services/Printed-P/printed-cover2.webp","../images/Services/Printed-P/printed-cover3.webp","../images/Services/Printed-P/printed-cover4.webp"],
    metaTitle:"",
    metaDes:"",
    metaKeywords:""
},
{
    id:7,
    name:"Leather Patches", 
    slug:"leather-patches",
    image:"../images/Services/Leather-P/leather-cover.webp",
    content:"Leather patches add a touch of sophistication and durability to any item, offering a premium, classic look that is perfect for branding, fashion, and accessories. Leather patches showcase quality and craftsmanship, making them perfect for denim, bags, hats, or jackets. These patches can be customized with embossed, debossed, or printed designs, allowing for a unique and stylish finish. Available in various shades, textures, and shapes, leather patches are ideal for adding a timeless appeal to your products. Known for their durability and resistance to wear, they age beautifully, becoming even more distinctive over time. Perfect for both casual and luxury brands, leather patches are an elegant way to enhance your product’s visual appeal, giving it a professional and rugged charm.",
    booking:"Customize your leather patch order online, selecting design details, shape, and size for a streamlined, easy-to-use ordering experience.",
    design:"We provide a digital proof for your approval, allowing any necessary revisions to ensure the leather patch matches your vision perfectly.",
    order:"After design approval, production begins, using top-quality leather to create patches that offer durability and a sophisticated look.",
    portfolioImage:["../images/Services/Leather-P/leather-cover2.webp","../images/Services/Leather-P/leather-cover3.webp","../images/Services/Leather-P/leather-cover4.webp","../images/Services/Leather-P/leather-cover.webp"],
    metaTitle:"",
    metaDes:"",
    metaKeywords:""
}
]
export default patchesDetailData;