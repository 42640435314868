import React, { useEffect, useState } from "react";
import "../../Style/css/ServicesDetailsContent2.css";
import QuoteButtonWithHover from "./FreeQuoteButton";
import QuoteButtonWithWhite from "./FreeQuoteWhite";


const ServicesDetailsContent2 = () => {
  const [activeSection, setActiveSection] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const component = document.querySelector(".services-details-content");
    const sections = document.querySelectorAll(".service-section");

    const handleScroll = (event) => {
      // Disable special scrolling on mobile and tablet view
      if (window.innerWidth <= 768) return;

      if (isScrolling) return;

      // Calculate component's boundaries to detect if user is inside
      const { top, bottom } = component.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      // Check if the component is in view
      if (top < viewportHeight && bottom > 0) {
        const deltaY = event.deltaY;
        let newSection = deltaY > 0 ? activeSection + 1 : activeSection - 1;

        // Ensure newSection index is within bounds
        newSection = Math.max(0, Math.min(newSection, sections.length - 1));

        if (newSection !== activeSection) {
          setIsScrolling(true);
          sections[newSection].scrollIntoView({ behavior: "smooth" });
          setActiveSection(newSection);

          setTimeout(() => setIsScrolling(false), 100);
        }
      }
    };

    // Add event listener for wheel scrolling only if on desktop
    if (window.innerWidth > 768) {
      window.addEventListener("wheel", handleScroll);
    }

    // Cleanup on component unmount
    return () => window.removeEventListener("wheel", handleScroll);
  }, [activeSection, isScrolling]);

  return (
    <div className="services-details-content">
  <div className="service-section">
    <div className="service-content">
      <div className="service-image">
        <img src="../images/Services/Embroidery Digitizing/demo-cap2.webp" alt="Hat Embroidery Digitizing" className="fade-down" width="600" height="600"/>
      </div>
      <div className="service-text">
        <h2>Cap Embroidery Digitizing</h2>
        <p>We bring precision to cap embroidery digitizing; all your designs come to life flawlessly on any style of cap. With our expert touch, your logos and artwork come alive, vibrant and tailored just right on headwear.</p>
        <div className="mt-15">
          <QuoteButtonWithHover/>
        </div>

      </div>
    </div>
  </div>

  <div className="service-section special">
    <div className="service-content reverse">
      <div className="service-image">
        <img src="../images/Services/Embroidery Digitizing/demo-left-chest2.webp" alt="Logo Embroidery Digitizing" className="fade-down" width="600" height="600"/>
      </div>
      <div className="service-text">
        <h2>Left Chest Embroidery Digitizing</h2>
        <p>Give the perfect impression with our left chest embroidery digitizing services. We will make sure you have the accuracy and flair to give a distinguished view on designs for shirts, jackets, and uniforms to define your brand in a very smooth and respectful manner.</p>
        <div className="mt-15">
          <QuoteButtonWithWhite />
        </div>
      </div>
    </div>
  </div>

  <div className="service-section">
    <div className="service-content">
      <div className="service-image">
        <img src="../images/Services/Embroidery Digitizing/demo-logo-embroidery-digitizing.webp" alt="Jacket Back Embroidery Digitizing" className="fade-down" width="600" height="600" />
      </div>
      <div className="service-text">
        <h2>Logo Embroidery Digitizing</h2>
        <p>Our logo embroidery digitizing converts your brand logo into an appealing stitched design that speaks for itself. Subtle touches or highly detailed embroidery, we ensure that our result will speak of professionalism and style by representing your brand at its best.</p>
        <div className="mt-15 bg-white">
          <QuoteButtonWithHover/>
        </div>
      </div>
    </div>
  </div>
</div>

  );
};

export default ServicesDetailsContent2;
