import React from 'react'
import Layout1 from '../layouts/Layout1'
import ServicePricing from '../components/Patches/ServicePricing'
import { Helmet } from 'react-helmet'
import PatchesDetailsContent from '../components/PatchesDetails/PatchesDetailsContent'
import ServiceDetailBreadcrumb from '../components/PatchesDetails/ServiceDetailBreadcrumb'


export default function CustomPatches() {
    return (
        <>
        <Helmet>
        <title>Our Patches | Silk Digitizing: Custom Patches</title>
        <meta name="description" content="Explore Silk Digitizing's range of patches, including Custom Patches, Embroidered Patches, and Chenille Patches. Quality craftsmanship with a quick turnaround." />
        <meta name="keywords" content="Custom Patches services, vector conversion services, graphic design services, embroidery designs, vector art, Silk Digitizing services"/>
        <link rel="canonical" href="https://silkdigitizing.com/custom-patches"></link>
        </Helmet>
            <Layout1>
                <ServiceDetailBreadcrumb/>
                {/* <ServiceFeature /> */}
                <PatchesDetailsContent/>
                <ServicePricing/>
                {/* <ServiceSection/> */}
                {/* <ServiceWorkingProgres/> */}
                {/* <ServiceStrategy/> */}
            </Layout1>
        </>
    )
}
