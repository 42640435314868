import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

export default function ServiceDetailBreadcrumb() {
    const { pathname } = useLocation();
    const { name } = useParams();
    let newStr = name?.replace(/-/g, ' ');

    return (
        <>
            {/* <!-- breadcrumb section start --> */}
            <section className='container-fluid newbreadcrumb'>
                <div className='newbreadcrumb-columnleft'>
                    <img src='/images/shape/breadcrumb-shape.webp' alt='' style={{ width: '100%', height: '100%' }} />
                </div>
                <div className='newbreadcrumb-columnRight'>
                    <img src="/images/HomePage/hand-prints-cover.webp" alt="" style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
                    <div className='newbreadcrumb-overlay'></div>
                </div>
                <div className="newbreadcrumbbreadcrumb-text">
                    <h1 className="breadcrumb-text__title">
                        {pathname === "/custom-patches" ? "Custom Patches" : newStr}
                    </h1>
                    <ul className="breadcrumb-text__nav wrap-cancel">
                        <li><Link to="/">Home</Link></li>
                        <li>-</li>
                        {pathname !== "/custom-patches" && (
                            <>
                                <li><Link to="/custom-patches">Custom Patches</Link></li>
                                <li>-</li>
                            </>
                        )}
                        <li>{pathname === "/custom-patches" ? "Custom Patches" : newStr}</li>
                    </ul>
                </div>
            </section>
            {/* <!-- breadcrumb section end --> */}
        </>
    );
}
