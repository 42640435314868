import React, { useState } from 'react';
import axios from 'axios';
import VideoPopUp from '../Plugins/VideoPopup';

export default function HomeTwoVideo() {
  const [isPopUp, setPopUp] = useState(false);
  const [userdata, setUserdata] = useState({
    name: "",
    email: "",
    phone: "",
    service: "",
    message: "",
    file: null
});

const [statusMessage, setStatusMessage] = useState({ text: "", type: "" });

const handleChange = (e) => {
    const { name, value } = e.target;
    setUserdata({
        ...userdata,
        [name]: value
    });
};

const handleFileChange = (e) => {
    setUserdata({
        ...userdata,
        file: e.target.files[0]
    });
};

const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const formData = new FormData();
        formData.append("name", userdata.name);
        formData.append("email", userdata.email);
        formData.append("phone", userdata.phone);
        formData.append("service", userdata.service);
        formData.append("message", userdata.message);
        if (userdata.file) {
            formData.append("file", userdata.file);
        }

     const res =   await axios.post('https://www.silkdigitizing.com/cp/contact_action.asp', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        console.log('API Response:', res.data); // Log the response
        setStatusMessage({ text: "Message Sent", type: "success" });

        // Clear the status message
        setTimeout(() => {
            setStatusMessage({ text: "", type: "" });
        }, 5000);
    } catch (err) {
        setStatusMessage({ text: "Try Again, Sending Failed", type: "error" });

        // Clear the status message 
        setTimeout(() => {
            setStatusMessage({ text: "", type: "" });
        }, 5000);
    }
};

  return (
    <>
    {/* Form Status Message */}
     {statusMessage.text && (
                <div className={`  status-message ${statusMessage.type} fade`}>
                    {statusMessage.text}
                </div>
            )}
      {/* Video area start */}
      <section className="video-area">
        <div className="container-fluid">
          <div className="row no-gutters">
            <div className="col-xl-8">
              <div className="video__bg" style={{ position: 'relative', height: '100%', width: '100%' }}>
                <img
                  src="/images/bg/embroidery-machine.webp"
                  alt="Embroidery Machine"
                  loading="lazy"
                  style={{
                    objectFit: 'cover',
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: -1,
                  }}
                />
                <div className="video-container-wrap">
                  <a
                    onClick={() => setPopUp(true)}
                    className="video-link pointer"
                    data-rel="lightcase:myCollection"
                    data-animation="fadeInLeft"
                    data-delay=".1s"
                  >
                    <div className="video-play-wrap">
                      <div className="video-mark">
                        <div className="wave-pulse wave-pulse-1"></div>
                        <div className="wave-pulse wave-pulse-2"></div>
                      </div>
                      <div className="video-play">
                        <i className="fa fa-play"></i>
                      </div>
                    </div>
                  </a>
                  <a
                    onClick={() => setPopUp(true)}
                    className="video-text pointer"
                    data-rel="lightcase:myCollection"
                    data-animation="fadeInLeft"
                    data-delay=".1s"
                  >
                    Play Video
                  </a>
                </div>
              </div>
              {isPopUp && (
                <VideoPopUp
                  setShow={() => setPopUp(false)}
                  src="/images/About-Us/Aboutpagevideo.mp4"
                  preload="none"
                />
              )}
            </div>

            <div className="col-xl-4">
           
              <div className="quote-wrapper">
                <h2 className="quote-title">Free quote</h2>

                

                <div className="quote-form">
                  <form onSubmit={handleSubmit} className="mt-none-15">
                    <div className="form-group mt-15">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Contact Name"
                        value={userdata.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group mt-15">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Contact Email"
                        value={userdata.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group mt-15">
                      <input
                        type="tel"
                        name="phone"
                        id="tel"
                        placeholder="Contact Number"
                        value={userdata.phone}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group mt-15">
                      <select
                        name="service"
                        id="service"
                        value={userdata.service}
                        onChange={handleChange}
                        style={{ width: '100%' }}
                        aria-label="selector option"
                      >
                        <option value=" " disabled>
                          Choose Service
                        </option>
                        <option value="Embroidery Digitizing">Embroidery Digitizing</option>
                        <option value="Vector Conversion">Vector Conversion</option>
                        <option value="Custom Patches">Custom Patches</option>
                      </select>
                    </div>
                    <div className="form-group mt-15">
                      <input type="file" name="file" id="file" onChange={handleFileChange} />
                    </div>
                    <div className="form-group mt-15">
                      <textarea
                        name="message"
                        id="message"
                        placeholder="Write Message"
                        value={userdata.message}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="form-group mt-15">
                      <button type="submit" className="quote-btn" aria-label="For Submission">
                        <span className="icon">
                          <i className="far fa-arrow-right"></i>
                        </span>
                        Free Estimate
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Video area end */}
    </>
  );
}
