import React from 'react';
import { Link } from 'react-router-dom';



const styles = {
    prominentButton: {
        padding: '17px 30px',
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#8d7ecb',
        backgroundColor: '#ffffff',
        backgroundImage: 'linear-gradient(135deg, #ffffff 0%, #ffffff 100%)', // Gradient in shades
        border: 'none',
        borderRadius: '12px',
        cursor: 'pointer',
        transition: 'transform 0.2s, background-color 0.2s',
        display: 'inline-block',
    },
    prominentButtonHover: {
        backgroundImage: 'linear-gradient(135deg, #3e3e7a 0%, #3e3e7a 100%)',
        padding: '7px 17px',
        fontSize: '16px',
        fontWeight: 'bold',
        color: 'white',
    },
};

// Add a hover effect by attaching an event to apply hover style
const handleMouseEnter = (e) => {
    e.target.style.backgroundImage = styles.prominentButtonHover.backgroundImage;
    e.target.style.transform = 'scale(1.1)';
};

const handleMouseLeave = (e) => {
    e.target.style.backgroundImage = styles.prominentButton.backgroundImage;
    e.target.style.transform = 'scale(1)';
};

// Attach the hover effect to the button
const QuoteButtonWithWhite = () => (
    <Link to="/contact#contact"> <button
        style={styles.prominentButton}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
    >
        GET QUOTE NOW

    </button></Link>
);

export default QuoteButtonWithWhite;
