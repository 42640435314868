import React from 'react';
import { useState } from 'react';
import NewStyleTestimonials from './NewStyleTestimonails';
import axios from 'axios';
import EmbDigitizingLast from './EmbDigitizingComponent';
import ServicesDetailsContent2 from './ServicesDetailsContent2';
import ContactBanner from './ContactBanner';
import WorkingProcessContent from './WorkingProcessContent';
import EDBreadcrumb from './EmbroideryDigitBreadCrumb';
import QuoteButtonWithHover from './FreeQuoteButton';
import ViewPortfolioBtn from './ViewPortfolioBtn';



export default function EmbroideryDigitizing() {
    const [userdata, setUserdata] = useState({
        name: "",
        email: "",
        phone: "",
        service: "",
        message: "",
        file: null
    });

    const [statusMessage, setStatusMessage] = useState({ text: "", type: "" });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserdata({
            ...userdata,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        setUserdata({
            ...userdata,
            file: e.target.files[0]
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData();
            formData.append("name", userdata.name);
            formData.append("email", userdata.email);
            formData.append("phone", userdata.phone);
            formData.append("service", userdata.service);
            formData.append("message", userdata.message);
            if (userdata.file) {
                formData.append("file", userdata.file);
            }

          const res =  await axios.post('https://www.silkdigitizing.com/cp/contact_action.asp', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });

            console.log('API Response:', res.data); // Log the response
            setStatusMessage({ text: "Message Sent", type: "success" });

            // Clear the status message after 20 seconds
            setTimeout(() => {
                setStatusMessage({ text: "", type: "" });
            }, 5000);
        } catch (err) {
            setStatusMessage({ text: "Try Again, Sending Failed", type: "error" });

            // Clear the status message after 20 seconds
            setTimeout(() => {
                setStatusMessage({ text: "", type: "" });
            }, 5000);
        }
    };

    return (
        <>
          {statusMessage.text && (
                <div className={`status-message ${statusMessage.type} fade`}>
                    {statusMessage.text}
                </div>
            )}
            <EDBreadcrumb />
            {/* <!-- details content start --> */}
            <div className="details-content-area pt-100 pb-100" >
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="sidebar grey-bg mt-0">
                                <h2 className="sidebar__title">
                              
                                    <span><img src="/images/shape/heading-shape-3.png" className="mr-5" alt="" width="14" height="14" /></span>
                                    Quick Inquiry
                                </h2>
                                <div className="contact-form contact-form__3">
                                    <form onSubmit={handleSubmit} >
                                        <div className="form-group mt-20">
                                            <input type="text" name="name" id="name" placeholder="Your Name" value={userdata.name}
                                                onChange={handleChange} />
                                        </div>
                                        <div className="form-group mt-20">
                                            <input type="email" name="email" id="email" placeholder="Email Address" value={userdata.email}
                                                onChange={handleChange} />
                                        </div>
                                        <div className="form-group mt-20">
                                            <input type="tel" name="phone" id="tel" placeholder="Your phone number" value={userdata.phone}
                                                onChange={handleChange} />
                                        </div>
                                        <div className="form-group mt-20">
                                            <select
                                            aria-label="Select One Option"
                                                name="service"
                                                id="service"
                                                value={userdata.service}
                                                onChange={handleChange}
                                                style={{ width: '100%', background: "#fff", borderRadius: "0", border: "none" }}
                                            >
                                                <option value="" disabled>Choose Service</option>
                                                <option value="Embroidery Digitizing">Embroidery Digitizing</option>
                                                <option value="Vector Conversion">Vector Conversion</option>
                                            </select>
                                        </div>
                                        <div className="form-group mt-20"><input type="file" name="file" id="file" onChange={handleFileChange}  />
                                        </div>
                                        <div className="form-group mt-20">
                                            <textarea name="message" id="message" placeholder="Your Message" value={userdata.message}
                                                onChange={handleChange}></textarea>
                                        </div>
                                        <div className='mbc'>
                                        <button type="submit" className="site-btn site-btn__2 mt-10 "><span
                                            className="icon icon__black"><i className="far fa-arrow-right"></i></span>Get Free Quote
                                        </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8">

                            <div className="details-content" >



                                <h2 className="details-content__title mb-20">Embroidery Digitizing Service</h2>
                                <p>Welcome to the home of top-notch embroidery digitizing! We specialize in transforming your artwork,
                                    logos, and designs into high-quality embroidery files that deliver outstanding results on any fabric.
                                </p>
                           <div className='strong'>
                                <ul class="mt-20 mb-20 ">
                                    <li className='pricing-sd'>
                                        Pricing
                                    </li>
                                  
                                        <li>Text Name (Left Chest / Cap) - $10</li>
                                        <li>Left Chest / Cap Design (under 10k stitches) - $15</li>
                                        <li>Medium Size Design - $20</li>
                                        <li>Jacket Front (under 40k stitches) Min - $30</li>
                                        <li>Jacket (up to 100k stitches) - $75</li>
                                   
                                </ul>
                                </div>
                                <div className='mbc'>
                                <QuoteButtonWithHover />
                                </div>
                                
                            </div>

                        </div>


                        <div className='col-xl-12'>
                            <div className="details-content" >
                                <div className=" project-area project-area__2 pt-80 pb-80" style={{ paddingTop: '100px' }}>
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-xl-8 text-center">
                                                <div className="section-header mb-65">
                                                    <h3 className="sub-heading sub-heading__2 mb-15">
                                                        <span><img src="/images/shape/heading-shape-3.png" className="mr-5" alt="" width="14" height="14" /></span>
                                                        Project showcase
                                                        <span><img src="/images/shape/heading-shape-4.png" className="ml-5" alt="" width="14" height="14" /></span>
                                                    </h3>
                                                    <h3 className="section-title section-title__2">Recent Projects</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid">
                                        <div className="row project-row mt-none-30">
                                            {/* Image One */}
                                            <div className="col-md-6 mt-30" >
                                                <div className="project-item">
                                                    <div className="project-item__thumb">
                                                        <img src="../../images/Portfolio/EmbroideryDigitizing/FlatEmbroideryDigitizing/nitty-logo.webp" alt="" width="612" height="284" />
                                                    </div>
                                                    <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                                        <div className="project-item__content">
                                                            {/* <h5 className="project-item__subtitle"><span>//</span> Book Print</h5> */}
                                                            <h4 className="project-item__title">Embroidery Digitizing</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Image Two */}
                                            <div className="col-md-6 mt-30" >
                                                <div className="project-item">
                                                    <div className="project-item__thumb">
                                                        <img src="../../images/Portfolio/EmbroideryDigitizing/FlatEmbroideryDigitizing/GMK-logo.webp" alt="" width="612" height="401" />
                                                    </div>
                                                    <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                                        <div className="project-item__content">
                                                            {/* <h5 className="project-item__subtitle"><span>//</span> Book Print</h5> */}
                                                            <h4 className="project-item__title">Embroidery Digitizing</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Image Three */}
                                            <div className="col-md-6 mt-30" >
                                                <div className="project-item">
                                                    <div className="project-item__thumb">
                                                        <img src="../../images/Portfolio/EmbroideryDigitizing/FlatEmbroideryDigitizing/RACK-RACER-DESIGN.webp" alt="" width="612" height="372" />
                                                    </div>
                                                    <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                                        <div className="project-item__content">
                                                            {/* <h5 className="project-item__subtitle"><span>//</span> Book Print</h5> */}
                                                            <h4 className="project-item__title">Embroidery Digitizing</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Image Four */}
                                            <div className="col-md-6 mt-30" >
                                                <div className="project-item">
                                                    <div className="project-item__thumb">
                                                        <img src="../../images/Portfolio/EmbroideryDigitizing/FlatEmbroideryDigitizing/Gogi-logo.webp" alt="" width="612" height="397" />
                                                    </div>
                                                    <div className="project-item__hover" data-overlay="dark" data-opacity="9">
                                                        <div className="project-item__content">
                                                            {/* <h5 className="project-item__subtitle"><span>//</span> Book Print</h5> */}
                                                            <h4 className="project-item__title">Embroidery Digitizing</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                           

                                        </div>
                                        <center className='mt-40'>
                                            <ViewPortfolioBtn/>
                                           </center>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <h2 className="section-title section-title__2 text-center mt-10">CLIENT TESTIMONIALS </h2>
            <div>
                <NewStyleTestimonials />
            </div>
            <EmbDigitizingLast />
            <ServicesDetailsContent2 />
            <WorkingProcessContent />
            <ContactBanner />
            {/* <!-- details content end -->    */}
        </>
    )
}
