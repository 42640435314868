import React from 'react';
import { useEffect, useState } from 'react';

const EmbDigitizingLast = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 500px)');
    const handleMediaChange = (e) => setIsSmallScreen(e.matches);

    // Check initial screen size
    setIsSmallScreen(mediaQuery.matches);

    // Add listener for media query changes
    mediaQuery.addEventListener('change', handleMediaChange);

    // Cleanup event listener on component unmount
    return () => mediaQuery.removeEventListener('change', handleMediaChange);
  }, []);

  const styles = {
    container: {
      maxWidth: '1200px', // Limit the max width on larger screens
      margin: '0 auto',
      padding: '20px',
    },
    title: {
      fontSize: '24px',
      marginBottom: '10px',
      textAlign: 'left',
      margin: '0 18px',
      fontWeight: '600',
    color: '#0C2957',
      
    },
    paragraph: {
      width: '100%', // Full width on mobile
      maxWidth: '1200px', // Limit width for readability on larger screens
      margin: '15px 20px',
    //   marginBottom: '20px',
      fontSize: '16px',
      lineHeight: '1.6',
      color: '#333',
      textAlign: 'left',
    },
    contentRow: {
      display: 'flex',
      flexWrap: 'wrap', // Allows wrapping on smaller screens
      gap: '12px', // Space between list and image
      justifyContent: 'space-between',
      
    },
    listContainer: {
      flex: '1 1 60%', // Occupies around 60% of width on larger screens
      maxWidth: '500px',
      marginLeft: '18px'
    },
    list: {
      listStyleType: 'disc',
      paddingLeft: '20px', // Aligns bullets
      fontSize: '16px',
      lineHeight: '1.5',
      color: '#333',
    },
    listItem: {
      marginBottom: '10px',
    },
    image: {
      flex: '1 1 40%', // Occupies around 40% of width on larger screens
      maxWidth: isSmallScreen ? '330px' : '630px', // Responsive max width
      width: '100%',
      height: 'auto',
      marginBottom: '20px', // Spacing below on mobile
      order: 2, // Ensures image is on the right side
    },
  };

  return (
    <div style={styles.container}>
      {/* Title Section */}
      <h2 style={styles.title}>Embroidery Digitizing Services</h2>
      
      {/* Paragraph Section */}
      <p style={styles.paragraph}>
      We specialize in transforming images into machine-ready embroidery files with a remarkably quick turnaround. This process, known as embroidery digitizing or logo digitization, goes beyond simple image format conversion. Instead, it involves translating any graphic design into intricate stitch patterns that can be embroidered on fabric. Unlike screen printing, which focuses on graphic transfer, embroidery digitizing centers on stitch-based representation. This art form requires a specific embroidery machine, advanced embroidery software, and the expertise of a skilled digitizer. Both embroidery and screen printing offer unique ways to showcase custom logos on apparel.
      </p>
      
      {/* Content Row with List and Image */}
      <div style={styles.contentRow}>
        {/* List Section */}
        <div style={styles.listContainer}>
          <ul style={styles.list}>
          <li style={styles.listItem}>Cap Embroidery Digitizing</li>
            <li style={styles.listItem}>Left Chest Embroidery Digitizing</li>
            <li style={styles.listItem}>Logo Embroidery Digitizing</li>
            <li style={styles.listItem}>Jacket Back Embroidery Digitizing</li>
            <li style={styles.listItem}>Sleeves Embroidery Digitizing</li>

            <li style={styles.listItem}>Flat Embroidery Digitizing</li>
            <li style={styles.listItem}>Applique Embroidery Digitizing</li>
            <li style={styles.listItem}>3D Puff Embroidery Digitizing</li>
            <li style={styles.listItem}>Chenille Embroidery Digitizing</li>
            <li style={styles.listItem}>Sequin Embroidery Digitizing</li>
          </ul>
        </div>

        {/* Image Section */}
        <div className='micEDL'>
        <img
          src="../../images/Portfolio/EmbroideryDigitizing/FlatEmbroideryDigitizing/tribal-man.webp"
          alt="Tribesman Design"
          style={styles.image}
       width="630" height="277"
       />
        </div>
      </div>
    </div>
  );
};



export default EmbDigitizingLast;
