import React from 'react'

export default function HomeTwoWorkingProcess() {
    return (
        <>
         {/* <!-- working-process area start --> */}
    <section className="wp-area pt-100 pb-100">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-8 text-center">
                    <div className="section-header mb-65">
                        <h3 className="sub-heading mb-15">
                            <span><img src="/images/shape/heading-shape-3.avif" className="mr-5" alt="" width={14} height={14} style={{maxWidth: '100%'}} /></span>
                            working process
                            <span><img src="/images/shape/heading-shape-4.avif" className="ml-5" alt="" width={14} height={14} style={{maxWidth: '100%'}} /></span>
                        </h3>
                        <h3 className="section-title section-title__2">How it works</h3>
                    </div>
                </div>
            </div>
            <div className="row mt-none-40">
                <div className="col-xl-3 col-lg-6 col-md-6 mt-40">
                    <div className="wp-box">
                        <div className="wp-box__icon wp-box__icon--1 mb-35">
                            <img src="/images/icons/pencil.webp" alt="Booking Icon" width={50} height={50} style={{maxWidth: '100%'}}/>
                        </div>
                        <div className="wp-box__content">
                            <h4 className="wp-box__title">Booking Online</h4>
                            <p>Effortlessly book our services online, saving your time and hassle. With just a few clicks, you can secure our expertise for your projects.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 mt-40">
                    <div className="wp-box reverse-col">
                        <div className="wp-box__content">
                            <h4 className="wp-box__title">Received Work</h4>
                            <p>Receive your completed projects promptly and efficiently. We ensure timely delivery, so you can proceed with your plans without delay.</p>
                        </div>
                        <div className="wp-box__icon wp-box__icon--2 mt-35">
                            <img src="/images/icons/cards.avif" alt="Received Work Icon" width={43} height={47} style={{maxWidth: '100%'}}/>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 mt-40">
                    <div className="wp-box">
                        <div className="wp-box__icon wp-box__icon--3 mb-35">
                            <img src="/images/icons/satisfied-design.avif" alt="Satisfied Design Icon" width={64} height={46} style={{maxWidth: '100%'}}/>
                        </div>
                        <div className="wp-box__content">
                            <h4 className="wp-box__title">Satisfied Design</h4>
                            <p>Be delighted by the quality and precision of our designs. We strive for excellence in every detail, ensuring that your vision is brought to life with utmost satisfaction.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 mt-40">
                    <div className="wp-box reverse-col">
                        <div className="wp-box__content">
                            <h4 className="wp-box__title">Start Digitizing</h4>
                            <p>Embark on your digitizing journey with us. Whether it's embroidery, vector conversion, we're here to fulfill your digitizing needs with dedication and expertise.</p>
                        </div>
                        <div className="wp-box__icon mt-35">
                            <img src="/images/icons/start-digitizing.avif" alt="Start Digitizing Icon" width={55} height={44} style={{maxWidth: '100%'}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- working-process area end -->    */}
        </>
    )
}
