import React, { useEffect, useState } from "react";
import "../../Style/css/ServicesDetailsContent2.css";
import QuoteButtonWithHover from "../ServiceDetails/FreeQuoteButton";
import QuoteButtonWithWhite from '../ServiceDetails/FreeQuoteWhite';

const VectorConversionContent = () => {
  const [activeSection, setActiveSection] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const component = document.querySelector(".services-details-content");
    const sections = document.querySelectorAll(".service-section");

    const handleScroll = (event) => {
      // Disable special scrolling on mobile and tablet view
      if (window.innerWidth <= 768) return;

      if (isScrolling) return;

      // Calculate component's boundaries to detect if user is inside
      const { top, bottom } = component.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      // Check if the component is in view
      if (top < viewportHeight && bottom > 0) {
        const deltaY = event.deltaY;
        let newSection = deltaY > 0 ? activeSection + 1 : activeSection - 1;

        // Ensure newSection index is within bounds
        newSection = Math.max(0, Math.min(newSection, sections.length - 1));

        if (newSection !== activeSection) {
          setIsScrolling(true);
          sections[newSection].scrollIntoView({ behavior: "smooth" });
          setActiveSection(newSection);

          setTimeout(() => setIsScrolling(false), 100);
        }
      }
    };

    // Add event listener for wheel scrolling only if on desktop
    if (window.innerWidth > 768) {
      window.addEventListener("wheel", handleScroll);
    }

    // Cleanup on component unmount
    return () => window.removeEventListener("wheel", handleScroll);
  }, [activeSection, isScrolling]);

  return (
    <div className="services-details-content">
  <div className="service-section">
    <div className="service-content">
      <div className="service-image">
        <img src="../images/Portfolio/Vector-Line-Art-Creation.webp" alt="Vector Line Art" className="fade-down" width="600" height="600"/>
      </div>
      <div className="service-text">
        <h2>Vector Line Art Creation</h2>
        <p>Elevate your designs with precise vector line art creation!
           Our skilled team transforms concepts into clean, scalable line art, 
           perfect for logos, illustrations, or technical drawings. Experience 
           unmatched accuracy and quality, bringing your vision to life beautifully.
          </p>
        <div className="mt-15">
          <QuoteButtonWithHover/>
        </div>

      </div>
    </div>
  </div>

  <div className="service-section special">
    <div className="service-content reverse">
      <div className="service-image">
        <img src="../images/Portfolio/Logo-Vectorization-Logo.webp" alt="Logo Embroidery Digitizing" className="fade-down" width="600" height="600"/>
      </div>
      <div className="service-text">
        <h2>Logo Vectorization</h2>
        <p>Convert your logo into an impeccable, scalable art piece with our professional logo 
          vectorization service. Perfect for printing, embroidery, or digital use, 
          our experts will produce high-quality, crisp work that will make your brand shine, everywhere.</p>
        <div className="mt-15">
          <QuoteButtonWithWhite />
        </div>
      </div>
    </div>
  </div>

  <div className="service-section">
    <div className="service-content">
      <div className="service-image">
        <img src="../images/Portfolio/Photo-to-Vector-Illustration.webp" alt="Jacket Back Embroidery Digitizing" className="fade-down" width="600" height="600" />
      </div>
      <div className="service-text">
        <h2>Photo to Vector Illustration</h2>
        <p>Turn your photos into stunning, scalable vector illustrations with our expert services. 
          Perfect for branding, printing, or creative projects, we craft detailed, high-quality designs
           that capture every nuance, ensuring your vision comes to life with exceptional precision.</p>
        <div className="mt-15 bg-white">
          <QuoteButtonWithHover/>
        </div>
      </div>
    </div>
  </div>
</div>

  );
};

export default VectorConversionContent;
